<template>
  <div class="impressum">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="text-h4 mb-1">Impressum</h1>
          <v-divider class="my-3"></v-divider>

          <h2 class="mb-2">
            Informationspflicht laut
            <a
              href="https://www.gesetze-im-internet.de/tmg/__5.html"
              target="_blank"
              >§ 5 TMG</a
            >
          </h2>
          <p>
            <span class="ta">TrustyApps</span><br />
            Marvin Rust<br />
            Fuhlsbüttler Straße 302<br />
            22307 Hamburg,<br />
            Deutschland
          </p>
          <p><strong>USt. ID-Nr.:</strong> DE335876424</p>
          <p><strong>e-Mail:</strong> contact@trustyapps.de</p>
          <p>
            <strong>Berufsbezeichnung:</strong> Ingenieur der Elektro- und
            Informationstechnik
          </p>

          <h2 class="mb-2">Haftung für Inhalte</h2>
          <p>
            Wir entwickeln die Inhalte dieser Webseite ständig weiter und
            bemühen uns korrekte und aktuelle Informationen bereitzustellen.
            Laut Telemediengesetz
            <a
              href="https://www.gesetze-im-internet.de/tmg/__7.html?tid=321232114"
              target="_blank"
              >§ 7 Abs.1 TMG</a
            >
            sind wir als Diensteanbieter für eigene Informationen, die wir zur
            Nutzung bereitstellen, nach den allgemeinen Gesetzen verantwortlich.
            Leider können wir keine Haftung für die Korrektheit aller Inhalte
            auf dieser Webseite übernehmen, speziell für jene die seitens
            Dritter bereitgestellt wurden. Als Diensteanbieter im Sinne der §§ 8
            bis 10 des TMG sind wir nicht verpflichtet, die von ihnen
            übermittelten oder gespeicherten Informationen zu überwachen oder
            nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
            hinweisen.
          </p>
          <p>
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon
            unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
            Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
            Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
            Inhalte umgehend entfernen.
          </p>
          <p>
            Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen,
            bitte wir Sie uns umgehend zu kontaktieren, damit wir die
            rechtswidrigen Inhalte entfernen können. Sie finden die Kontaktdaten
            im Impressum.
          </p>

          <h2 class="mb-2">Haftung für Links</h2>
          <p>
            Unser Angebot enthält Links zu externen Websites Dritter, auf deren
            Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
            verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
            der Seiten verantwortlich. Die verlinkten Seiten wurden zum
            Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar.
          </p>
          <p>
            Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte
            wir Sie uns zu kontaktieren. Sie finden die Kontaktdaten im
            Impressum.
          </p>

          <h2 class="mb-2">Urheberrechtshinweis</h2>
          <p>
            Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos)
            unterliegen dem Urheberrecht der Bundesrepublik Deutschland. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der
            Inhalte unserer Seite rechtlich verfolgen.
          </p>
          <p>
            Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
            Inhalte umgehend entfernen
          </p>
        </v-col>
        <v-col cols="12" class="mx-auto text-center">
          <v-btn @click="top">Zum Anfang der Seite</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  methods: {
    top() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
</style>